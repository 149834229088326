import { useStaticQuery, graphql } from 'gatsby'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import HeaderAndText from '../components/header-and-text';
import Hero from '../components/hero';
import ImageLeftCard from '../components/image-left-card';
import LearnMore from '../components/learn-more';
import Layout from '../layouts/layout';

const WhyEdgePage = () => {

    const data = useStaticQuery(graphql`
        query WhyEdgeQuery {
            contentfulLayout(contentful_id: {eq: "5ekLKWZN8Zue6Mnyyt7ifK"}) {
                contentModule {
                    ... on ContentfulContentObject {
                        title
                        description {
                        description
                        }
                        media {
                            url
                        }
                        link_text
                    }
                }
                hero {
                    header_title
                    hero_body {
                      hero_body
                    }
                    heroImage {
                      url
                    }
                }
            }
        }
    `)

    const page = data.contentfulLayout

    const hero1Title = page.hero.header_title
    const hero1Body  = page.hero.hero_body.hero_body
    const hero1Image = page.hero.heroImage.url

    const headerText    = page.contentModule[0].title

    const imgLeftCard1Title = page.contentModule[1].title
    const imgLeftCard1Text  = page.contentModule[1].description.description
    const imgLeftCard1Image = page.contentModule[1].media.url
    const imgLeftCard1Index = 1

    const hero2Title    = page.contentModule[2].title
    const hero2Body     = page.contentModule[2].description.description
    const hero2Image    = page.contentModule[2].media.url

    const imgLeftCard2Title = page.contentModule[3].title
    const imgLeftCard2Text  = page.contentModule[3].description.description
    const imgLeftCard2Image = page.contentModule[3].media.url
    const imgLeftCard2Index = 2

    const learnMoreTitle    = page.contentModule[4].title
    const learnMoreLinkText = page.contentModule[4].link_text

    const contactTitle    = page.contentModule[5].title
    const contactBody     = page.contentModule[5].description.description
    const contactImage    = page.contentModule[5].media.url
    const contactLink     = page.contentModule[5].link_url
    const contactLinkText = page.contentModule[5].link_text

    return(
        <Layout>
            <Helmet>
                <title>Why O3 Edge | Benefits | Retirement Plan Prospecting</title>
                <meta name="description" content="Learn why O3 Edge is the right solution for your team’s lead generation and plan analysis needs. Contact us to get more information." />
            </Helmet>

            <div className="why-o3">
                <Hero title={hero1Title} body={hero1Body} image={hero1Image} />

                <div className="section-container bg-secondary">
                    <HeaderAndText header={headerText} />
                </div>

                <ImageLeftCard 
                    header={imgLeftCard1Title} 
                    text={imgLeftCard1Text} 
                    image={imgLeftCard1Image} 
                    index={imgLeftCard1Index} 
                    />

                <div style={{ backgroundImage: `url(${hero2Image})`, backgroundPosition: "top right" }} className="hero-image">
                    <Container>
                        <Row>
                            <Col lg="6" className="hero-img-content offset-lg-6 text-center text-lg-left">
                                <h1 className="text-primary">{hero2Title}</h1>
                                <p>{hero2Body}</p>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <ImageLeftCard 
                    header={imgLeftCard2Title} 
                    text={imgLeftCard2Text} 
                    image={imgLeftCard2Image} 
                    index={imgLeftCard2Index} 
                    />

                <LearnMore linkText={learnMoreLinkText} title={learnMoreTitle} />

                <div style={{ backgroundImage: `url(${contactImage})`}} className="contact-hero">
                    <Container>
                        <Row>
                            <Col className="hero-img-content text-center">
                                <h1 className="text-white">{contactTitle}</h1>
                                <h2 className="mb-5 text-white">{contactBody}</h2>
                                <a href={contactLink} className="btn btn-secondary">{contactLinkText}</a>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </Layout>
    )
}

export default WhyEdgePage
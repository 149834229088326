import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

interface HeroProps {
    title: String,
    body: String,
    image: String
}

const Hero = (props: HeroProps) => {
    return(
        <div className="hero">
            <div className="background-container background-container-image" style={{ backgroundImage: `url(${props.image})` }}></div>
            <Container>
                <Row>
                    <Col className="hero-content hero-img-content text-center text-lg-left" lg="8">
                        <h1 className="text-primary">
                            {props.title}
                        </h1>
                        {props.body ? <h2>{props.body}</h2> : null}
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Hero
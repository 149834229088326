import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

interface ImageLeftCardProps {
    header: String,
    text: String,
    image: String,
    index: number
}

const ImageLeftCard = (props: ImageLeftCardProps) => {
    return(
        <div className="horizontal-card-img-left">
            <Container>
                <Row>
                    <Col md="6" className="pt-5 pt-md-0 d-flex">
                        <div className ="d-flex flex-column justify-content-center pl-5 text-container">
                            <h2 className="text-primary fw-bolder">{props.header}</h2>
                            <p>{props.text}</p>
                        </div>
                    </Col>
                    <Col md="6">
                        <video autoPlay loop muted id={`video-${props.index}`}>
                            <source src={props.image.toString()} />
                        </video>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ImageLeftCard;